import * as React from "react";
import Navigation from "../sections/Navigation";
import Hero from "../sections/Hero";
import Solutions from "../sections/Solutions";
import About from "../sections/About";
import Partner from "../sections/Partner";
import Footer from "../sections/Footer";
import "../style/index.scss";

const IndexPage = () => {
  return (
    <div>
      <Navigation />
      <Hero />
      <Solutions />
      <About />
      <Partner />
      <Footer />
    </div>
  );
};

export default IndexPage;
