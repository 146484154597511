import React from "react";
import { Link } from "gatsby";
import { solutions } from "../data/solutions";
import { company } from "../data/general";

const Solutions = () => {
  return (
    <div
      id="solutions"
      className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
    >
      <div className="absolute inset-0">
        <div className="h-1/3 sm:h-2/3 bg-ms-blue" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-white sm:text-4xl">
            D365 Solutions
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-white sm:mt-4">
            {company.mediumName} offers end to end dynamics 365 solution to
            empower your business
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
          {solutions.map((solution) => (
            <div
              key={solution.name}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={solution.imageUrl}
                  alt={company.fullName}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <div className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">
                      {solution.name}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {solution.description}
                    </p>
                  </div>
                </div>
                <div className="mt-6 flex items-center">
                  <Link
                    to={solution.href}
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Know More <span aria-hidden="true">&rarr;</span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Solutions;
