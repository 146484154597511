import React from "react";
import PartnerOne from "../images/partners/partner-1.png";
import PartnerTwo from "../images/partners/partner-2.png";
import PartnerThree from "../images/partners/partner-3.png";
import PartnerFour from "../images/partners/partner-4.png";
import PartnerFive from "../images/partners/partner-5.png";

const Partners = () => {
  return (
    <div className="bg-ms-blue" id="partners">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl tracking-tight font-extrabold text-white sm:text-4xl">
            Partners
          </h2>
        </div>
        <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-5">
          <div className="col-span-1 flex justify-center m-4 py-8 px-8 bg-gray-50">
            <img src={PartnerOne} alt="Beak Consulting" />
          </div>
          <div className="col-span-1 flex justify-center m-4 py-8 px-8 bg-gray-50">
            <img src={PartnerTwo} alt="Charge Logic" />
          </div>
          <div className="col-span-1 flex justify-center m-4 py-8 px-8 bg-gray-50">
            <img src={PartnerThree} alt="Lanham Associates" />
          </div>
          <div className="col-span-1 flex justify-center m-4 py-8 px-8 bg-gray-50">
            <img src={PartnerFour} alt="Matriks" />
          </div>
          <div className="col-span-1 flex justify-center m-4 py-8 px-8 bg-gray-50">
            <img src={PartnerFive} alt="Limestone" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
