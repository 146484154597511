import React from "react";
import Patterns from "../aux/patterns";
import { company } from "../data/general";
import TeamImage from "../images/team.png";

const About = () => {
  return (
    <div id="about" className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            About Us
          </h3>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <Patterns
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
            />
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                <img
                  className="rounded-lg shadow-lg object-cover object-center"
                  src={TeamImage}
                  alt={company.fullName}
                  width={1184}
                  height={1376}
                  style={{ height: 410 }}
                />
              </div>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="text-base max-w-prose mx-auto text-lg text-gray-500 lg:max-w-none text-justify pr-4">
              <p>
                {company.shortName} offers a comprehensive suite for intelligent
                IoT and Industry 4.0.
              </p>
              <br />
              <p>
                AI powered Trail Camera solutions powered by the GSM IoT module
                process millions of images per day and provide insights in
                real-time.
              </p>
              <br />
              <p>
                Our unrelenting R&D in computer Vision harvested breakthroughs
                in Image Processing & Image Analysis, Night Vision Colorization,
                and Object Recognition powered by proprietary solutions.
              </p>
              <br />
              <p>
                Our Smart Connected Products & Solutions gain rapid mileage for
                products in logistics, outdoor sports & healthcare.
              </p>
              <br />
              <p>
                Smart Connected Services upgrades the field service industry and
                upthrusts the KPI/KRA in all industries including manufacturing,
                energy & utilities, transportation, telecommunications, retail,
                and insurance.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
