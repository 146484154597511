import * as React from "react";
import HeroImage from "./../images/hero/hero.svg";
import DynamicsPartner from "./../images/logo/dynamics-partner.svg";
import Patterns from "../aux/patterns";

const Hero = () => {
  return (
    <div id="hero" className="flex bg-gray-50 relative">
      <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left flex">
        <Patterns className="absolute top-0 left-0" width={404} height={784} />
        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16 z-10">
          <div style={{ width: 340, marginBottom: 70, marginTop: 65 }}>
            <img src={DynamicsPartner} alt="" className="hidden lg:flex" />
          </div>
          <h1 className="text-5xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span className="block xl:inline">
              Microsoft Dynamics 365 Solutions
            </span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            Implement, Integrate & Customize Dynamics Business Central, F&O and
            more D365 Solutions
          </p>
        </div>
        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16 z-10 hidden lg:flex">
          <img className="" src={HeroImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
